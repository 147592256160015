<template>
    <div>
        <!-- <Spinner v-if="lord" />
        

        <p v-for="infor in informacion" :key="infor.id">

          {{infor}}
        </p> -->
    </div>
</template>

<script>
// import Spinner from '@/components/auth/Spinner/Spinner.vue'
export default {
   
  name: "AttributeUser",
  components:{
  //   Spinner
  },
  data() {
    return {
      // payment_id: null,
      // informacion: [],
      // lord:true
    };
  },

//   methods: {
//     getAttributes() {

//       this.axios
//         .get("course/details/3")
//         .then((datos) => {
//           this.lord=false
          // console.log(datos.data.data);
//           this.informacion = datos.data.data;
//           console.log(this.informacion)
//         });
//     },
//   },
//   created() {
//     this.getAttributes();
//   },
};


</script>